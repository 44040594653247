<template>
  <div class="RealTimeVideoWrapper">
    <Split direction="row"
      :sideOffset="0.25">
      <template #first>
        <RealTimeSide :updateVehicleTime="realTimeConfig.updateVehicleTime"
          :onlyVideo="true"
          @group-check="onGroupCheck"
          @vehicle-click="onVehicleClick"
          @vehicle-check="onVehicleCheck"
          @vehicle-check-all="onVehicleCheckAll"
          @updateVehicles="onUpdateVehicles">
          <template #vehicleAction="{ data }">
            <el-dropdown placement="right-end"
              @command="onCommandClick($event, data)">
              <span>
                <Iconfont name="icon-bofang"></Iconfont>
              </span>
              <el-dropdown-menu slot="dropdown">
                <!-- 视频设备 and 在线 and ACC开 可看视频 -->
                <el-dropdown-item v-if="data.isVideo"
                  :disabled="!(([0, 1, 2, 3, 4].includes(data.Z)) && data.mediaOnline)"
                  :command="1">
                  <Iconfont name="icon-shishishipin-daohang"
                    :mRight="7"></Iconfont>
                  <span>实时视频</span>
                </el-dropdown-item>
                <el-dropdown-item :command="2">
                  <Iconfont name="icon-guiji"
                    :mRight="7"></Iconfont>
                  <span>轨迹回放</span>
                </el-dropdown-item>
                <el-dropdown-item :command="3">
                  <Iconfont name="icon-xinxifuzhi"
                    :mRight="7"></Iconfont>
                  <span>信息复制</span>
                </el-dropdown-item>
                <el-dropdown-item :command="4">
                  <Iconfont name="icon-woyaoshouquan"
                    :mRight="7"></Iconfont>
                  <span>我要授权</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </RealTimeSide>
      </template>

      <template #second>
        <Split direction="row"
          :sideOffset="0.7"
          :padding="0">
          <template #first>
            <RtpPlayer :videoList="videoList"
              :speakVehicle="speakVehicle"
              :videoGrid="realTimeConfig.videoGrid"
              :videoCloseTime="realTimeConfig.videoCloseTime"
              @playStart="onPlayStart"
              @playEnd="onPlayEnd"
              @speakEnd="onSpeakEnd"
              :realZ="realZ"></RtpPlayer>
          </template>

          <template #second>
            <RtpMap v-model="currentVehicle"
              :minClusterSize="realTimeConfig.vehicleMinClusterNum"
              :vehicleList="checkedVehicleList"
              :speakVehicle="currentSpeak"
              @playClick="handlePlayClick"
              @speakClick="onSpeakClick"></RtpMap>
          </template>
        </Split>
      </template>
    </Split>

    <!-- 车辆授权 -->
    <AuthCoin v-model="autoCoinObj.visible"
      :vehicleList="autoCoinObj.vehicleList"></AuthCoin>
  </div>
</template>


<script>
import { mapState } from "vuex";

import Split from "@/components/Split";

import RealTimeSide from "@/components/RealTimeSide";

import RtpPlayer from "@/views/RealTimePositioning/RtpPlayer.vue";
import RtpMap from "@/views/RealTimePositioning/RtpMap.vue";
import { getTreeNodeId } from "@/utils/treeHelper";
import { copyText } from "@/utils/clipboard";
import AuthCoin from "@/components/AuthCoin";

export default {
  // `name`与路由保持一致
  name: "RealTimeVideo",
  components: {
    Split,
    RealTimeSide,
    RtpPlayer,
    RtpMap,
    AuthCoin
  },
  data() {
    return {
      // 勾选的车辆
      checkedVehicleList: [],
      currentVehicle: null,
      // 实时视频列表
      videoList: [],
      // 当前对讲车辆
      currentSpeak: null,

      // 授权
      autoCoinObj: {
        visible: false,
        vehicleList: []
      },
      realZ: undefined,
      videoPlate: undefined
    };
  },
  computed: {
    ...mapState(["userConfig", "userInfo"]),
    // 中国石油判断
    isZgsy() {
      return (
        this.$store.state.userRouter?.children?.find(
          (r) => r.code === "zgsy"
        ) && this.$store.state.userInfo.name != "广东一立科技"
      );
    },
    realTimeConfig() {
      return this.userConfig.realTimeConfig;
    },

    speakVehicle() {
      const { currentSpeak } = this;
      if (!currentSpeak) return null;

      // 终端号
      const device = currentSpeak.N;
      // 设备协议类型 1: GF-1078, GB-1078; 2: GA系列
      const protocolType = ["GF-1078", "GB-1078"].includes(currentSpeak.T)
        ? 1
        : 2;
      // 特殊协议 0: 不处理; 1: 粤标
      const SpecialSign = currentSpeak.percentageOfFlow === 6 ? 1 : 0;
      // 设备名称
      const plate = currentSpeak.P;
      // 车辆Id
      const vehicleId = currentSpeak.V;
      // 车组Id
      const groupId = currentSpeak.M;

      return {
        device,
        protocolType,
        SpecialSign,
        plate,
        vehicleId,
        groupId
      };
    }
  },
  methods: {
    async onCommandClick(command, data) {
      switch (command) {
        case 1: // 实时视频
          this.videoPlate = data.P;
          this.handlePlayClick(data);
          break;
        case 2: // 轨迹回放
          this.$router.push({
            name: "TrackPlayback",
            params: {
              vehicle: data
            }
          });
          break;
        case 3: // 复制信息
          const text = [
            `设备名称: ${ data.P }`,
            `车组名称: ${ data.groupName }`,
            `终端号: ${ data.N }`,
            // `经纬度: ${data.X},${data.Y}`,
            `SIM: ${ data.SIM }`
            // `车辆Id: ${data.V}`,
            // `车组Id: ${data.M}`,
          ].join("\r\n");
          console.log("copy", text);

          await copyText(text);
          this.$message.success("复制成功");

          break;
        case 4: // 授权
          this.autoCoinObj.vehicleList = [
            {
              vehicleId: data.V,
              plate: data.P,
              terminalNo: data.N,
              terminalType: data.T,
              groupId: data.M
            }
          ];

          this.autoCoinObj.visible = true;
          break;
      }
    },
    handlePlayClick(data) {
      const videoList = [...this.videoList];
      const channels = this.parseChannels(data.camreaLine);

      // 终端号
      const device = data.N;
      // 设备协议类型 1: GF-1078, GB-1078; 2: GA系列
      const protocolType = ["GF-1078", "GB-1078"].includes(data.T) ? 1 : 2;
      // 特殊协议 0: 不处理; 1: 粤标
      const SpecialSign = data.percentageOfFlow === 6 ? 1 : 0;
      // 设备名称
      const plate = data.P;
      // 车辆Id
      const vehicleId = data.V;
      // 车组Id
      const groupId = data.M;

      channels.forEach((channel) => {
        const key = getTreeNodeId(data.M, data.V, channel);
        if (videoList.some((obj) => obj.key === key)) return;
        videoList.push({
          key,
          channel,
          device,
          protocolType,
          SpecialSign,
          plate,
          vehicleId,
          groupId
        });
      });

      this.videoList = videoList;
    },
    parseChannels(channelStr) {
      try {
        return JSON.parse(channelStr);
      } catch (err) {
        //
      }
      return [];
    },
    onGroupCheck(allCheckeds) {
      const { checkedVehicleList, currentVehicle, videoList } = this;
      const groupIds = allCheckeds.map((p) => p.groupId);

      if (groupIds.length > 0 && checkedVehicleList.length > 0) {
        // 当前车辆未包含在选中的车组中
        if (currentVehicle && !groupIds.includes(currentVehicle.M)) {
          this.currentVehicle = null;
        }

        // 留下包含在选中车组中的车辆
        this.checkedVehicleList = checkedVehicleList.filter((obj) =>
          groupIds.includes(obj.M)
        );

        // 留下包含在选中车组的通道
        this.videoList = videoList.filter((obj) =>
          groupIds.includes(obj.groupId)
        );
      } else {
        this.checkedVehicleList = [];
        this.videoList = [];
        this.currentVehicle = null;
      }
    },
    onVehicleClick(data) {
      if ([0,1, 2, 3].includes(data.Z)) {
        this.currentVehicle = data;
      }
    },
    onVehicleCheck(data, checked, node, allCheckeds) {
      // 车辆
      if (data.nodeType === 2) {
        this.checkedVehicleList = allCheckeds
          .filter((obj) => [0,1, 2, 3].includes(obj.Z))
          .filter((obj) => obj.X && obj.Y);

        if (checked) {
          if ([0,1, 2, 3].includes(data.Z)) {
            this.currentVehicle = data;
          }
        } else if (
          this.currentVehicle &&
          this.currentVehicle.key === data.key
        ) {
          this.currentVehicle = null;
        }
      }
    },
    onVehicleCheckAll(checked, allCheckeds) {
      const list = allCheckeds
        .filter((obj) => [0,1, 2, 3].includes(obj.Z))
        .filter((obj) => obj.X && obj.Y);

      // 勾选 取第一个
      if (checked && list.length) {
        this.currentVehicle = list[0];
      } else {
        this.currentVehicle = null;
      }

      this.checkedVehicleList = list;
    },
    onSpeakClick(vehicle) {
      const { currentSpeak } = this;
      if (currentSpeak && vehicle.key !== currentSpeak.key) {
        return this.$message.warning(`请先关闭 ${ currentSpeak.P } 对讲`);
      }

      if (currentSpeak && vehicle.key === currentSpeak.key) {
        this.currentSpeak = null;
        return;
      }

      this.currentSpeak = vehicle;
    },
    onPlayStart(channelObj) {
      const { videoList } = this;
      if (!videoList.some((obj) => obj.key === channelObj.key)) {
        videoList.push(channelObj);
      }
    },
    onPlayEnd(channelObj) {
      const { videoList } = this;
      this.videoList = videoList.filter((obj) => obj.key !== channelObj.key);
      if (!this.videoList || !this.videoList.length) {
        this.videoPlate = null;
      }
    },
    onSpeakEnd() {
      this.currentVehicle = null;
      this.videoPlate = null;
    },
    onUpdateVehicles(vehicles) {
      if (!vehicles.length) return;
      //更新后的车辆集合
      let newVehicles = vehicles.map((arr) => arr[0]);
      //更新前的车辆集合
      // const oldVehicles = vehicles.map((arr) => arr[1]);
      if (this.videoPlate) {
        newVehicles = newVehicles.filter((item) => item.P == this.videoPlate);
        if (newVehicles[0]) {
          const { Z } = newVehicles[0];
          this.realZ = Z;
        }
      }
      //举例 判断某个车辆的状态 (Z)是否变化
      // newVehicles[0].Z新变化所有的状态
      // if (newVehicles[0].Z != 2) {
      //   console.log(`车辆[0]的车辆状态变化，更新后${newVehicles[0].Z}`);
      // }
    }
  }
};
</script>

<style lang="scss" scoped>
.RealTimeVideoWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>